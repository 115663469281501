var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"86286ac154b461e42eb8ffbda5d95b434b0af40e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole } from "@sentry/integrations";
import { sanityConfig, libryContentVersion } from "@libry-content/common";

const { dataset } = sanityConfig;

const isPreview = () =>
  typeof window !== "undefined" && new URL(window.location.href).searchParams.get("preview") === "true";

const getHostname = () => (typeof window !== "undefined" ? new URL(window.location.href).hostname : undefined);

const getRelease = () => libryContentVersion;

const isNoise = (url) =>
  typeof url === "string" &&
  (url.startsWith("/_next/static") || url.startsWith("/_vercel/insights") || url.startsWith("https://plausible.io/"));

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 0.05, // Use tracesSampler for finer control
    // Note: if you want to override the automatic release value, do not set a `release` value here -
    // use the environment variable `SENTRY_RELEASE`, so that it will also get attached to your source maps
    environment: dataset,
    integrations: [
      new CaptureConsole({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ["error"],
      }),
    ],
    ignoreErrors: [
      // https://github.com/getsentry/sentry/issues/12676
      "Network request failed",
      "Failed to fetch",
      "NetworkError",
      "Failed to lookup route:",
    ],
    beforeBreadcrumb(breadcrumb) {
      // Filtrer ut forespørsler som bare gir støy i Sentry, f.eks. forespørsler
      // etter statisk CSS/JS og analytics (Vercel Insights, Plausible)
      return breadcrumb?.type === "http" && isNoise(breadcrumb?.data?.url) ? null : breadcrumb;
    },
    release: getRelease(),
  });

  Sentry.configureScope((scope) => {
    if (isPreview()) scope.setTag("libryContent.preview", true);

    const siteDomain = getHostname();
    if (siteDomain) scope.setTag("site_domain", siteDomain);
  });
}
